<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getNostrificationReport"
  :isLoading="isLoading"
  :saveReport="getNostrificationReportExcel"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'id', text: 'ID', sortable: false },
        { value: 'created_at', text: this.$t('tableHeaders.created_at'), sortable: false },
        { value: 'number', text: this.$t('tableHeaders.number'), sortable: false },
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailor.full_name_ukr', text: this.$t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'sailor.full_name_eng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'manager', text: this.$t('tableHeaders.manager_full_name'), sortable: false },
        { value: 'is_payed_nostrification', text: this.$t('tableHeaders.isPayedNostrification'), sortable: false },
        { value: 'is_payed_translate', text: this.$t('tableHeaders.isPayedTranslate'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_document'), sortable: false },
        { value: 'nostrification_document_status', text: this.$t('tableHeaders.nostrification_document_status'), sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.report.nostrification,
      isLoading: (state) => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getNostrificationReportExcel', 'getNostrificationReport'])
  }
}
</script>
